import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import Grid from '../components/Grid'
import NextSections from '../components/NextSections'

// import images
import fullImage1 from '../images/alloy-chemical-presentation/alloy-pres-full-1.jpg'
import fullImage2 from '../images/alloy-chemical-presentation/alloy-pres-full-2.jpg'
import gridImage1 from '../images/alloy-chemical-presentation/alloy-pres-grid-1.jpg'
import gridImage2 from '../images/alloy-chemical-presentation/alloy-pres-grid-2.jpg'

export default () => (
    <Project
        title={'alloy chemical presentation'}
            date={'2017'}
            position={'Graphic Designer'}
            employer={'Lasting Image'}
            brief={[
                'This presentation was created for a steel company to show their annual Alloy Chemical Data Report. The project began with over 30 excel spreadsheets listing chemical percentages, expenditures, supplier information and more.',
                'I was able to interpret the information and translate it into a visually appealing design that was consistent and precise. The presentation was requested for print only but could be adapted into digital presentation slides.'
            ]}
            skills={[
                'Data Interpretation',
                'Illustration & Layout Design',
                'Corporate Brand Standards'
            ]}
    >
        <FullWidthImage src={fullImage1} alt={'Guy holding computer'} />
        <Padding />
        <Grid
            images={[
                    {
                        src: gridImage1,
                        alt: 'Icons repesenting aspects of periodic elements'
                    },
                    {
                        src: gridImage2,
                        alt: 'World map with shapes representing Production, Mine, and Production/Mine'
                    }
            ]}
            columns={2}
        />
        <Padding />
        <FullWidthImage src={fullImage2} alt={'project slides on gray background'} />
        <Padding />
        <NextSections
            previous={'online-shopping-infographic'}
            next={'mens-comfort-decoded-merchandise'}
        />
    </Project>
)